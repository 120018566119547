/* .logo {
  max-width: 211px !important;
}
.logo-white {
  max-width: 100px !important;
} */

.bg-primary {
  background-color: #232e5e !important;
}

header .sticky-header.fixed {
  background: #232e5e !important;
}

.contact-two * {
  text-align: left;
}

main>.container:not(.container-not-boxed):before {
  content: "";
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 0;
  bottom: 0;
  background: #fff !important;;
  z-index: -10;
}

.bg-white{
  background: #fff !important;;
}
