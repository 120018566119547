.product-list .product-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    padding-top: 0.8rem;

    color: #29418e;
}

.product-list .product-price {
    margin-bottom: 0.6rem;
    font-size: 1.25rem;
}

/* .product-list .product-price .new-price, .product-list .product-price .old-price {
    margin-bottom: 0.6rem;
    font-size: 1.25rem;
    color: #29418e !important;
} */

.product-list .product-price .old-price {
    /* margin-bottom: 0.6rem;*/
    font-size: 0.5rem;
}

.product-media{
    width: 100% !important;
}

.label-discount{
    color: #fff !important;
}